window.LiveElement = window.LiveElement || {}
window.LiveElement.Scale = window.LiveElement.Scale || Object.defineProperties({}, {
    version: {configurable: false, enumerable: true, writable: false, value: '1.0.0'}, 
    indexedDBName: {configurable: false, enumerable: true, writable: false, value: 'LiveElementScale'}, 
    emptyRecordJSONString: {configurable: false, enumerable: false, writable: false, value: JSON.stringify({
        body: {}, head: {$: [], previousTimestamp: 0, timestamp: 0, value: [], version: 0}
    })}, 
    allowableScopes: {configurable: false, enumerable: true, writable: false, value: ['Value', 'Record']}, 
    eventTarget: {configurable: false, enumerable: true, writable: false, value: new window.EventTarget()}, 
    Adaptors: {configurable: false, enumerable: true, writable: false, value: {}}, 
    storageAdaptor: {configurable: false, enumerable: true, writable: true, value: {}}, 
    computeAdaptor: {configurable: false, enumerable: true, writable: true, value: {}}, 
    alias: {configurable: false, enumerable: true, writable: false, value: {}}, 
    getValue: {configurable: false, enumerable: true, writable: false, value: function(hash) {
        hash  = this._resolveAlias(hash)
        return this._readLocal('Value', hash).then(body => {
            if (body && typeof body == 'string') {
                return window.fetch(body).then(r => {
                    return body.indexOf('data:application/json;') ? r.blob() : r.json()
                })
            } else {
                return this.sync('Value', hash).then(exists => {
                    if (exists) {
                        return this.getValue(hash)
                    } else {
                        return null
                    }
                })
            }
        })
    }}, 
    putValue: {configurable: false, enumerable: true, writable: false, value: function(input) {
        return this._canonicaliseValue(input).then(cv => {
            return this._writeLocal('Value', ...cv).then(() => {
                this.sync('Value', cv[0])
                return cv
            })
        })
    }}, 
    viewRecord: {configurable: false, enumerable: true, writable: false, value: function(id, options={}) {
        id  = this._resolveAlias(id)
        return this._readLocal('Record', id).then(rawRecord => {
            if (rawRecord && typeof rawRecord == 'object') {
                if (options.expand == 'raw') {
                    return rawRecord
                } else {
                    let valuatedRecord = this._valuateRecord(rawRecord)
                    if (options.expand == 'valuate') {
                        return valuatedRecord
                    } else {
                        let valuesMap = {}
                        return window.Promise.all(Object.values(valuatedRecord).map(valueKey => this.getValue(valueKey).then(value => valuesMap[valueKey] = value))).then(() => {
                            return Object.assign({}, ...Object.keys(valuatedRecord).map(recordField => ({[recordField]: valuesMap[valuatedRecord[recordField]]})))
                        })
                    }
                }
            } else {
                return this.sync('Record', id).then(exists => {
                    if (exists) {
                        return this.viewRecord(id, options)
                    } else {
                        return JSON.parse(this.emptyRecordJSONString)
                    }
                })
            }
        })
    }}, 
    updateRecord: {configurable: false, enumerable: true, writable: false, value: function(id, input, head=null, options={}) {
        id  = this._resolveAlias(id)
        id = id || window.crypto.randomUUID()
        return this._canonicaliseRecord(input, head).then(cv => {
            return this.viewRecord(id, {expand: 'raw'}).then(storedRecord => {
                let [record, valuesToPut] = cv
                if (options.force || (storedRecord.head.value[0] != record.head.value[0])) {
                    let storedRecordVersion = (storedRecord.head.version || 0)
                    record.head.version = (record.head.version <= storedRecordVersion) ? storedRecordVersion+1 : record.head.version
                    record.head.previousTimestamp = parseInt(storedRecord.head.timestamp) || 0
                    let p = Object.keys(valuesToPut).sort().map(valueKey => this._writeLocal('Value', valueKey, valuesToPut[valueKey]))
                    return window.Promise.all(p).then(() => {
                        return this._writeLocal('Record', id, record).then(() => {
                            this.sync('Record', id)
                            return [id, record]
                        })
                    })
                }
            })
        })
    }}, 
    sync: {configurable: false, enumerable: true, writable: false, value: function(scope, key) {
        key  = this._resolveAlias(key)
        let sendSyncEvent = function(direction, $this, scope, key, item) {
            $this.eventTarget.dispatchEvent(new window.CustomEvent(`Sync${direction}${scope}-${key}`, {detail: {direction: direction, scope: scope, key: key, item: item}}))
            $this.eventTarget.dispatchEvent(new window.CustomEvent(`Sync${direction}${scope}`, {detail: {direction: direction, scope: scope, key: key, item: item}}))
            $this.eventTarget.dispatchEvent(new window.CustomEvent(`Sync${direction}`, {detail: {direction: direction, scope: scope, key: key, item: item}}))
            $this.eventTarget.dispatchEvent(new window.CustomEvent(`Sync`, {detail: {direction: direction, scope: scope, key: key, item: item}}))
        }
        switch(scope) {
            case 'Value':
                return this._readLocal(scope, key).then(localItem => {
                    return this.storageAdaptor.retrieveItem(scope, key).then(remoteItem => {
                        if (remoteItem && !localItem) {
                            sendSyncEvent('In', this, scope, key, remoteItem)
                            return this._writeLocal(scope, key, remoteItem).then(() => true)
                        } else if (localItem && !remoteItem) {
                            sendSyncEvent('Out', this, scope, key, localItem)
                            return this.storageAdaptor.sendItem(scope, key, localItem).then(() => true)
                        } else {
                            return false
                        }
                    })
                })
            break
            case 'Record':
                return this._readLocal(scope, key).then(localRecord => {
                    return this.storageAdaptor.retrieveItem(scope, key).then(remoteItem => {
                        let remoteRecord 
                        try {
                            remoteRecord = JSON.parse(remoteItem)
                        } catch(e) {
                            remoteRecord = null
                        }
                        if (remoteRecord && !localRecord) {
                            sendSyncEvent('In', this, scope, key, remoteRecord)
                            return this._writeLocal(scope, key, remoteRecord).then(() => true)
                        } else if (localRecord && !remoteRecord) {
                            sendSyncEvent('Out', this, scope, key, localRecord)
                            return this.storageAdaptor.sendItem(scope, key, JSON.stringify(localRecord)).then(() => true)
                        } else if (localRecord && remoteRecord) {
                            if (localRecord.head.timestamp > remoteRecord.head.timestamp) {
                                if (localRecord.head.previousTimestamp > remoteRecord.head.previousTimestamp) {
                                    sendSyncEvent('Out', this, scope, key, localRecord)
                                    return this.storageAdaptor.sendItem(scope, key, JSON.stringify(localRecord)).then(() => true)
                                } else if (remoteRecord.head.previousTimestamp > localRecord.head.previousTimestamp) {
                                    sendSyncEvent('In', this, scope, key, remoteRecord)
                                    return this._writeLocal(scope, key, remoteRecord).then(() => true)
                                } else {
                                    return false
                                }
                            } else if (remoteRecord.head.timestamp > localRecord.head.timestamp) {
                                if (remoteRecord.head.previousTimestamp > localRecord.head.previousTimestamp) {
                                    sendSyncEvent('In', this, scope, key, remoteRecord)
                                    return this._writeLocal(scope, key, remoteRecord).then(() => true)
                                } else if (localRecord.head.previousTimestamp > remoteRecord.head.previousTimestamp) {
                                    sendSyncEvent('Out', this, scope, key, localRecord)
                                    return this.storageAdaptor.sendItem(scope, key, JSON.stringify(localRecord)).then(() => true)
                                } else {
                                    return false
                                }
                            } else {
                                return false
                            }
                        } else {
                            return false
                        }
                    })
                })
           break
        }
    }}, 
    run: {configurable: false, enumerable: true, writable: false, value: function(name, input=null, saveResultToRecordId=null, silent=false) {
        name = this._resolveAlias(name)
        saveResultToRecordId = saveResultToRecordId == true ? window.crypto.randomUUID() : this._resolveAlias(saveResultToRecordId)
        let sendEvent = (eventName, result) => {
            if (!silent) {
                this.eventTarget.dispatchEvent(new window.CustomEvent(`Run${eventName}-${name}`, {detail: {name: name, input: input, resultRecordId: saveResultToRecordId, result: result}}))
                this.eventTarget.dispatchEvent(new window.CustomEvent(`Run${eventName}`, {detail: {name: name, input: input, resultRecordId: saveResultToRecordId, result: result}}))
            }
        }
        sendEvent('Start')
        if (saveResultToRecordId) {
            return this.computeAdaptor.runProcessor(name, input).then(result => {
                sendEvent('End', result)
                if (window.Array.isArray(result)) {
                    return this.updateRecord(saveResultToRecordId, ...result).then(result => {
                        sendEvent('Saved', result)
                        return result
                    })
                } else if (result && typeof result == 'object') {
                    return this.updateRecord(saveResultToRecordId, result, {processor: name}).then(result => {
                        sendEvent('Saved', result)
                        return result
                    })
                } else {
                    return this.updateRecord(saveResultToRecordId, {result: result}, {processor: name}).then(result => {
                        sendEvent('Saved', result)
                        return result
                    })
                }
            })
        } else {
            if (saveResultToRecordId === false) {
                return this.computeAdaptor.runProcessor(name, input).then(result => {
                    sendEvent('End', result)
                    return result
                })
            } else {
                this.computeAdaptor.runProcessor(name, input).then(result => sendEvent('End', result))
                return window.Promise.resolve()
            }
        }
    }}, 
    _resolveAlias: {configurable: false, enumerable: false, writable: false, value: function(alias) {
        return alias && typeof alias == 'string' && alias.length > 1 && alias[0] === '$' ? this.alias[alias.slice(1)] : alias
    }}, 
    _canonicaliseValue: {configurable: false, enumerable: false, writable: false, value: function(value) {
        let body
        if (value && typeof value == 'string') {
            let rx = /^data:([a-z]+\/[a-z0-9-+.]+(;[a-z0-9-.!#$%*+.{}|~`]+=[a-z0-9-.!#$%*+.{}()|~`]+)*)?(;base64)?,([a-z0-9!$&',()*+;=\-._~:@\/?%\s<>]*?)$/i
            if (rx.test((value || '').trim())) {
                body = value
            }
        }
        body = body || `data:application/json;base64,${window.btoa(JSON.stringify(value))}`
        return window.crypto.subtle.digest('SHA-512', (new window.TextEncoder()).encode(body)).then(hashBuffer => {
            return [Array.from(new Uint8Array(hashBuffer)).map(b => b.toString(16).padStart(2, '0')).join(''), body]
        })
    }}, 
    _canonicaliseRecord: {configurable: false, enumerable: false, writable: false, value: function(fieldmap, head=null) {
        let record = {body: {}}, valuesToPut = {}
        record.head = head && typeof head == 'object' ? head : {}
        record.head.$ = []
        if (fieldmap && typeof fieldmap == 'object') {
            let p = Object.keys(fieldmap).sort().map(k => {
                return this._canonicaliseValue(fieldmap[k]).then(cv => {
                    if (!record.head.$.includes(cv[0])) {
                        record.head.$.push(cv[0])
                    }
                    record.body[k] = cv[0]
                    valuesToPut[cv[0]] = cv[1]
                })
            })
            return window.Promise.all(p).then(() => {
                return this._canonicaliseValue(record.body).then(cv => {
                    record.head.value = cv
                    record.head.version = (parseInt(record.head.version) || -1) + 1
                    record.head.previousTimestamp = parseInt(record.head.timestamp) || 0
                    record.head.timestamp = window.Date.now()/1000
                    Object.keys(record.body).forEach(k => {
                        record.body[k] = record.head.$.indexOf(record.body[k])
                    })
                    return [record, valuesToPut]
                })
            })
        } else {
            return window.Promise.reject('Error: no fieldmap provided')
        }
    }}, 
    _valuateRecord: {configurable: false, enumerable: false, writable: false, value: function(record) {
        return Object.assign({}, ...Object.keys(record.body).sort().map(k => ({[k]: record.head.$[record.body[k]]})))
    }}, 
    _accessLocal: {configurable: false, enumerable: false, writable: false, value: function(scope) {
        scope = this.allowableScopes.includes(scope) ? scope : null
        if (scope) {
            return new window.Promise((resolve, reject) => {
                let dbRequest = window.indexedDB.open(this.indexedDBName)
                dbRequest.addEventListener('upgradeneeded', event => {
                    let db = event.target.result
                    this.allowableScopes.forEach(s => {
                        if (!db.objectStoreNames.contains(s)) {
                            db.createObjectStore(s)
                        }
                    })
                })
                dbRequest.addEventListener('error', event => {
                    reject(new window.Error(`Error opening IndexedDB '${this.indexedDBName}'`))
                })
                dbRequest.addEventListener('success', event => {
                    resolve(event.target.result)
                })
            })
        } else {
            return Promise.reject(new window.Error('Error: scope not provided'))
        }
    }}, 
    _readLocal: {configurable: false, enumerable: false, writable: false, value: function(scope, key, silent=false) {
        return this._accessLocal(scope).then(db => {
            if (key) {
                return new window.Promise((resolve, reject) => {
                    let transaction = db.transaction(scope, 'readonly')
                    transaction.addEventListener('error', event => {
                        if (!silent) {
                            this.eventTarget.dispatchEvent(new window.CustomEvent(`ReadError${scope}-${key}`, {detail: {scope: scope, key: key}}))
                            this.eventTarget.dispatchEvent(new window.CustomEvent(`ReadError${scope}`, {detail: {scope: scope, key: key}}))
                            this.eventTarget.dispatchEvent(new window.CustomEvent(`ReadError`, {detail: {scope: scope, key: key}}))
                        }
                        reject(new window.Error(`Error reading from IndexedDB objectStore '${scope}', key '${key}'`))
                    })
                    let objectStore = transaction.objectStore(scope)
                    let objectStoreRequest = objectStore.get(key)
                    objectStoreRequest.addEventListener('success', event => {
                        //if desired to trigger an event for local read success, do it here
                        if (!silent) {
                            this.eventTarget.dispatchEvent(new window.CustomEvent(`Read${scope}-${key}`, {detail: {scope: scope, key: key, item: event.target.result}}))
                            this.eventTarget.dispatchEvent(new window.CustomEvent(`Read${scope}`, {detail: {scope: scope, key: key, item: event.target.result}}))
                            this.eventTarget.dispatchEvent(new window.CustomEvent(`Read`, {detail: {scope: scope, key: key, item: event.target.result}}))
                        }
                        resolve(event.target.result)
                    })
                })
            } else {
                return window.Promise.reject(new window.Error('Error: key not provided'))
            }
        })
    }}, 
    _writeLocal: {configurable: false, enumerable: false, writable: false, value: function(scope, key, obj, silent=false) {
        return this._accessLocal(scope).then(db => {
            if (key) {
                return new window.Promise((resolve, reject) => {
                    let transaction = db.transaction(scope, 'readwrite')
                    transaction.addEventListener('complete', event => {
                        if (!silent) {
                            this.eventTarget.dispatchEvent(new window.CustomEvent(`Write${scope}-${key}`, {detail: {scope: scope, key: key, item: obj}}))
                            this.eventTarget.dispatchEvent(new window.CustomEvent(`Write${scope}`, {detail: {scope: scope, key: key, item: obj}}))
                            this.eventTarget.dispatchEvent(new window.CustomEvent(`Write`, {detail: {scope: scope, key: key, item: obj}}))
                        }
                        resolve()
                    })
                    transaction.addEventListener('error', event => {
                        if (!silent) {
                            this.eventTarget.dispatchEvent(new window.CustomEvent(`WriteError${scope}-${key}`, {detail: {scope: scope, key: key, item: obj}}))
                            this.eventTarget.dispatchEvent(new window.CustomEvent(`WriteError${scope}`, {detail: {scope: scope, key: key, item: obj}}))
                            this.eventTarget.dispatchEvent(new window.CustomEvent(`WriteError`, {detail: {scope: scope, key: key, item: obj}}))
                        }
                        reject(new window.Error(`Error writing to IndexedDB objectStore '${scope}'`))
                    })
                    let objectStore = transaction.objectStore(scope)
                    let objectStoreRequest = objectStore.put(obj, key)
                })
            } else {
                return window.Promise.reject(new window.Error('Error: key not provided'))
            }
        })
    }}
})

